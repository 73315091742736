var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"importacoes-bdgd-historico","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-database-marker","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Importações de BDGD")])]},proxy:true}])},[(_vm.accessReleased('IMPORTACOES_DE_BDGD_ADICIONAR'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-nova-importacao-bdgd","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('add')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Nova importação ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-importacao-bdgd",staticStyle:{"max-width":"250px"},attrs:{"id":"importacao-bdgd-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"importacoes-bdgd-table",attrs:{"headers":_vm.headers,"items":_vm.importacoes,"search":_vm.search,"headerProps":{
            sortByText: 'Ordenar Por'
          },"footer-props":{
            itemsPerPageText: 'Itens por Página'
          },"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"mobile-breakpoint":800,"no-data-text":"Nenhuma importação de BDGD encontrada","no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("addLeadingZero")(item.id))+" ")]}},{key:"item.mes",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.mes))+" v"+_vm._s(item.versao)+" ")]}},{key:"item.numero_arquivos",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.numero_arquivos)+" ("+_vm._s(_vm._f("parseNumberToIntegerBR")(item.numero_linhas_pre_importadas))+_vm._s(item.numero_linhas_pre_importadas > 1 ? ' linhas' : ' linha')+") ")]}},{key:"item.numero_linhas_importadas",fn:function(ref){
          var item = ref.item;
return [_c('span',{style:({
                color:
                  item.percentual_linhas_importadas < 1 ||
                  item.numero_alertas_pre_importacao > 0
                    ? 'red'
                    : 'green'
              })},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.numero_linhas_importadas))+" ("+_vm._s(_vm._f("parseNumberToFloatBR")((100 * item.percentual_linhas_importadas)))+"%)"),(item.numero_linhas_nao_importadas)?_c('span',[_vm._v(", "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.numero_linhas_nao_importadas))+" "+_vm._s(item.numero_linhas_nao_importadas > 1 ? ' linhas não importadas' : ' linha não importada'))]):_vm._e(),(item.numero_alertas_pre_importacao)?_c('span',[_vm._v(", "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.numero_alertas_pre_importacao))+" "+_vm._s(item.numero_alertas_pre_importacao > 1 ? ' alertas' : ' alerta'))]):_vm._e()])]}},{key:"item.status_pre",fn:function(ref){
              var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status_pre}})]}},{key:"item.status_importacao",fn:function(ref){
              var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status_importacao}})]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(_vm.accessReleased('IMPORTACOES_DE_BDGD_LOGS'))?_c('v-btn',{staticClass:"px-1",attrs:{"id":"logs-importacao-bdgd","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$router.push(("logs/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-tree ")])],1):_vm._e()]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.getImportacoes}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar as importações de BDGD")])])]},proxy:true}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }