<template>
  <v-container
    id="importacoes-bdgd-historico"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-database-marker"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">Importações de BDGD</div>
          </template>

          <v-btn
            v-if="accessReleased('IMPORTACOES_DE_BDGD_ADICIONAR')"
            id="btn-nova-importacao-bdgd"
            color="success"
            dark
            elevation="1"
            class="mt-5"
            style="float: left"
            relative
            text
            medium
            @click="$router.push('add')"
          >
            <v-icon
              left
              size="30px"
            >
              mdi-plus-circle
            </v-icon>
            Nova importação
          </v-btn>

          <v-text-field
            id="importacao-bdgd-historico-search"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-importacao-bdgd"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />

          <v-divider class="mt-10" />

          <v-data-table
            class="importacoes-bdgd-table"
            :headers="headers"
            :items="importacoes"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            :mobile-breakpoint="800"
            no-data-text="Nenhuma importação de BDGD encontrada"
            no-results-text="Nenhum registro correspondente encontrado"
          >
            <template v-slot:[`item.id`]="{ item }">
              {{ item.id | addLeadingZero }}
            </template>
            <template v-slot:[`item.mes`]="{ item }">
              {{ item.mes | formatToMonth }} v{{ item.versao }}
            </template>
            <template v-slot:[`item.numero_arquivos`]="{ item }">
              {{ item.numero_arquivos }} ({{
                item.numero_linhas_pre_importadas | parseNumberToIntegerBR
              }}{{
                item.numero_linhas_pre_importadas > 1 ? ' linhas' : ' linha'
              }})
            </template>
            <template v-slot:[`item.numero_linhas_importadas`]="{ item }">
              <span
                :style="{
                  color:
                    item.percentual_linhas_importadas < 1 ||
                    item.numero_alertas_pre_importacao > 0
                      ? 'red'
                      : 'green'
                }"
              >
                {{ item.numero_linhas_importadas | parseNumberToIntegerBR }} ({{
                  (100 * item.percentual_linhas_importadas)
                    | parseNumberToFloatBR
                }}%)<span v-if="item.numero_linhas_nao_importadas"
                  >,
                  {{
                    item.numero_linhas_nao_importadas | parseNumberToIntegerBR
                  }}
                  {{
                    item.numero_linhas_nao_importadas > 1
                      ? ' linhas não importadas'
                      : ' linha não importada'
                  }}</span
                >
                <span v-if="item.numero_alertas_pre_importacao"
                  >,
                  {{
                    item.numero_alertas_pre_importacao | parseNumberToIntegerBR
                  }}
                  {{
                    item.numero_alertas_pre_importacao > 1
                      ? ' alertas'
                      : ' alerta'
                  }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.status_pre`]="{ item }">
              <general-status :status="item.status_pre" />
            </template>
            <template v-slot:[`item.status_importacao`]="{ item }">
              <general-status :status="item.status_importacao" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-if="accessReleased('IMPORTACOES_DE_BDGD_LOGS')"
                id="logs-importacao-bdgd"
                min-width="0"
                class="px-1"
                fab
                icon
                x-small
                @click="$router.push(`logs/${item.id}`)"
              >
                <v-icon small> mdi-file-tree </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`footer.page-text`]>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="getImportacoes"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Clique aqui para recarregar as importações de BDGD</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ImportacoesBdgdService from '@/services/ImportacoesBdgdService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  name: 'ImportacoesBdgdHistorico',
  mixins: [refreshDataMixins, tourMixins, profilePermissionsMixin],
  components: {
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue')
  },
  data: () => ({
    importacoes: [],
    search: '',
    headers: [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Título',
        value: 'titulo'
      },
      {
        text: 'Empresa',
        value: 'empresa'
      },
      {
        text: 'Mês dos dados',
        value: 'mes'
      },
      {
        text: 'Nº de arquivos',
        value: 'numero_arquivos'
      },
      {
        text: 'Linhas importadas',
        value: 'numero_linhas_importadas'
      },
      {
        text: 'Pré-importação',
        value: 'status_pre'
      },
      {
        text: 'Importação',
        value: 'status_importacao'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        class: 'pl-4'
      }
    ],
    arrTour: [
      {
        element: '#btn-nova-importacao-bdgd',
        intro: 'Clicando neste botão você poderá cadastrar novas importações.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#importacao-bdgd-historico-search',
        intro:
          'Neste campo você poderá pesquisar por importações já cadastradas.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#logs-importacao-bdgd',
        intro:
          'Clicando nesse botão você será redirecionado para a tela de logs, aonde será possível visualizar um resumo sobre a importação.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ],
    loading: false
  }),
  mounted() {
    this.refreshData(this.getImportacoes);
  },
  methods: {
    getImportacoes() {
      this.loading = true;
      ImportacoesBdgdService.getImportacoes()
        .then((importacoes) => {
          this.importacoes = importacoes;
          this.iniciarTourPagina();
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar as importações de BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },
    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let arrTourFormatted = this.importacoes.length
        ? this.arrTour
        : this.arrTour.splice(0, 2);
      this.iniciarTour(arrTourFormatted);
    }
  }
};
</script>

<style>
@media screen and (min-width: 800px) {
  .importacoes-bdgd-table table tr td:nth-child(2) {
    max-width: 200px;
  }

  .importacoes-bdgd-table table tr td:nth-child(7) {
    white-space: nowrap !important;
  }
}

@media screen and (max-width: 480px) {
  .bs-importacao-bdgd {
    width: 235.86px !important;
  }
}
</style>
